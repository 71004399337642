@import "accordions.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply font-entry;
}

h1, h2, h3, h4, h5, h6 {
    @apply font-heading text-primary mb-24;
}

h1 {
    @apply text-36 md:text-56 leading-48 md:leading-64;
}

h2 {
    @apply text-28 md:text-36;
}

h3 {
    @apply text-24 md:text-28;
}

h4 {
    @apply text-22 md:text-24;
}

h5 {
    @apply text-22 font-entry;
}

p {
    @apply text-text font-entry text-18 leading-28 mb-12;
}

ul, ol {
    @apply list-outside pl-18;
}

ul {
    @apply list-disc;
}

ol {
    @apply list-decimal;
}

.max-w-1792 {
    max-width: 112rem;
}

.radio-button-wrapper {
    @apply relative mr-8;
}

[x-cloak] {
    @apply hidden;
}

.custom-select {

    &.open {

        .select-chevron {
            @apply transform rotate-180;
        }
    }

    &:focus {
        @apply outline-none;

        .select-box {
            @apply ring ring-primary border-transparent;
        }
    }
}

body.ocm-open {
    @apply overflow-hidden;

    .ocm-toggle {

        .line {

            &.top {
                animation: OcmLineInTop 0.8s forwards;
            }

            &.middle {
                opacity: 0;
            }

            &.bottom {
                animation: OcmLineInBottom 0.8s forwards;
            }
        }
    }
}

.ocm-toggle {
    @apply relative block w-32 h-20;

    &:hover, &:focus {

        .line {

            &.middle {
                @apply left-1/2;
            }
        }
    }

    .line {
        @apply absolute h-2 bg-white transition-all;

        &.top {
            @apply top-0 left-0 right-0;
        }

        &.middle {
            @apply top-1/2 transform -translate-y-1/2 left-0 right-0;
        }

        &.bottom {
            @apply bottom-0 right-0 left-0;
        }
    }
}

@keyframes OcmLineInTop {
    0% {
        top: 0;
    }

    25% {
        top: 10px;
        transform: rotate(0);
    }

    100% {
        top: 10px;
        transform: rotate(135deg);
    }
}

@keyframes OcmLineInBottom {
    0% {
        top: 18px;
    }

    25% {
        top: 10px;
        transform: rotate(0);
    }

    100% {
        top: 10px;
        transform: rotate(-135deg);
    }
}

body.ocm-open {

    #ocm {
        @apply left-0 visible;
    }
}

body.notifications-ocm-open {
    @apply overflow-hidden;

    #notifications-ocm {
        z-index: 99999;
        @apply right-0 visible;
    }

    #main {

        &:after {
            @apply right-0;
        }
    }
}

#main {

    &:after {
        content: '';
        z-index: 99;
        @apply transition-all fixed left-0 right-full top-banner-visible-height bottom-0 bg-black opacity-80;
    }
}

.account-menu-wrapper {

    &.open {

        .account-menu {
            @apply opacity-100 bottom-full;
        }

        .open-chevron {
            @apply transform rotate-180;
        }
    }

    &.animating {

        .account-menu {
            @apply pointer-events-none;
        }
    }
}

#appraisals-table,
#requests-table {

    tbody {

        tr:not(.spacer) {
           @apply relative;

            &::after {
                content: '';
                z-index: -1;
                @apply absolute left-1 right-1 top-1 bottom-1 bg-section-background rounded-10;
            }

            &.has-border {

                &:after {
                    @apply border;
                }

                &.status-yellow {

                    &:after {
                        @apply border-status-yellow;
                    }
                }

                &.status-blue {

                    &:after {
                        @apply border-status-blue;
                    }
                }

                &.status-green {

                    &:after {
                        @apply border-status-green;
                    }
                }

                &.status-black {

                    &:after {
                        @apply border-status-black;
                    }
                }

                &.status-red {

                    &:after {
                        @apply border-status-red;
                    }
                }
            }
        }
    }
}

#reports-table {

    tbody {

        tr:not(.spacer) {
            @apply relative;

            &::after {
                content: '';
                z-index: -1;
                @apply absolute left-1 right-1 top-1 bottom-1 bg-section-background rounded-10;
            }

            &.has-border {

                &:after {
                    @apply border-2;
                }

                &.status-blue {

                    &:after {
                        @apply border-status-blue;
                    }
                }

                &.status-green {

                    &:after {
                        @apply border-status-green;
                    }
                }

                &.status-red {

                    &:after {
                        @apply border-status-red;
                    }
                }
            }
        }
    }
}